<script lang="ts" setup>
import { computed } from 'vue'
import type { ISbRichtext } from 'storyblok-js-client'

const props = defineProps<{
  text: ISbRichtext
  dark?: boolean
}>()

const resolvedRichText = computed(() => renderRichText(props.text))
</script>

<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div class="prose text-pretty" :class="{ 'prose-invert': dark }" v-html="resolvedRichText"></div>
</template>
